//TODO : Adapt markup infos within Markup bdd call (array / no array)
<template>
  <div>
    <!-- <div class="panel_header panel_item panel_title_marker">
      <span>Panneau de Création de Marqueurs</span>
    </div> -->
    <div class="panel_item">
      <div class="panel_item_title">Type et Status du repérage à ajouter</div>
      <div class="marker_selection panel_item_content">
        <i class="fad fa-2x marker_ic" :style="cssVars" :class="[markerClass]"></i>
        <select ref="selectType" @change="listFilteredTypes($event.target.value)">
          <option v-for="(type, i) in Object.keys(markersTypes)" :key="i" :value="type" :markerByType="markersTypes.type">{{type}}</option>
        </select >
        <select ref="selectStatus" @change="listFilteredStatus($event.target.value)">
          <option v-for="(status, i) in Object.keys(markerByType)" :key="i" :value="status">{{markerByType[status].label}}</option>
        </select >
      </div>
    </div>
    <div class="panel_item bts_draw">
      <div class="panel_item_title">Positionner le repérage</div>
      <i class="fad fa-map-marker-alt fa-2x marker_ic" @click="addMarker('marker')" :class="{selected: select.marker}"></i>
      <i class="fad fa-draw-polygon fa-2x marker_ic" @click="addMarker('zone')" :class="{selected: select.zone}"></i>
    </div>
    <modal v-if="modalShow" :title="modalTitle" @close="modalShow = false">
      <div slot="modal_content" v-html="modalContent"></div>
      <div slot="modal_footer" class="modal_footer">
      </div>
    </modal>
    <div class="panel_content">
      <div class="panel_item">
        <div class="panel_item_title">Informations BIM</div>
        <div v-if="drawType === 'marker'" class="marker_infos panel_item_content">
          <div class="param"><label class="paramLabel">Nom</label><span class="paramValue">{{objectName}}</span></div>
          <div class="param"><label class="paramLabel">Viewer Id</label><span class="paramValue">{{dbId}}</span></div>
          <div v-if="revitId.length > 0" class="param"><label class="paramLabel">Revit Id</label><span class="paramValue">{{revitId}}</span></div>
          <div class="param"><label class="paramLabel">Coord</label><span class="paramValue">{{coord}}</span></div>
        </div>
        <div v-else class="marker_infos">
          <div class="param"><label class="paramLabel">Nom</label><span class="paramValue">{{objectName}}</span></div>
          <div class="param"><label class="paramLabel">Viewer Id</label><span class="paramValue">{{dbId}}</span></div>
          <div v-if="revitId !== ''" class="param"><label class="paramLabel">Revit Id</label><span class="paramValue">{{revitId}}</span></div>
          <div class="param"><label class="paramLabel">Surface</label><span class="paramValue">{{surface}}</span></div>
          <div class="param"><label class="paramLabel">Coord</label><span class="paramValue">{{coord}}</span></div>
        </div>
      </div>
      <div class="panel_item">
        <div class="panel_item_title">Données associées</div>
        <div class="param"><label class="paramLabel">N° de prélèvement</label><span class="paramValue">{{markerItem.number}}</span></div>
        <div class="param"><label class="paramLabel">Date</label><span class="paramValue">{{markerItem.date}}</span></div>
        <div class="param"><label class="paramLabel">Auteur</label><span class="paramValue">{{markerItem.user}}</span></div>
        <div class="marker_data panel_item_content">
          <div class="">
            <img :src="markerItem.pictureUrl" class="marker_picture">
          </div>
          <div class="marker_comment">
            <span>{{markerItem.comment}}</span>
          </div>
        </div>
      </div>
      <div @click="saveMarker(markerItem)" class="bt_marker bt_save">Enregistrer</div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import modal from '../modal.vue';
  require('../../../scripts/viewer/viewerMarkers.js');
  let markersTypes = require('../../../scripts/viewer/viewerMarkersTypes.js').markersTypes;

  export default {
    name: 'App',
    data: function(){
        return{
          select: {
            marker: false,
            zone: false
          },
          modalShow: false,
          modalTitle: '',
          modalContent: '',
          modalAction: '',
          modalBt: 'Ok',
          markerItem: {
            id: "1111",
            number: "8",
            date: "21 octobre 2019",
            user: "John Doe",
            type: "",
            status: "",
            pictureUrl: this.pictureRandom(),
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer auctor mauris vitae neque euismod dignissim. In laoreet enim sit amet est dictum, id consectetur urna tempor. Mauris eu iaculis ipsum."
          },
          markersTypes: markersTypes,
          markerByType: "",
          markerType: "",
          markerStatus: "",
          markerClass: "fa-map-marker",
          colorExt: "#51a3d3",
          colorInt: "#cc2a27",
          types:"",
          drawType: "marker",
          newMarker: {},
          dbId:"",
          coord:"",
          objectName: "",
          revitId: "",
          surface: "m²"
        }
    },
    props: [],
    components:{
      modal: modal
    },
    filters: {
      adaptValue: function (value, key) {
        if (!value || value === ''|| value === undefined){ return 'non précisé' }
        switch(key){
          case 'fileSize':
            var i = Math.floor(Math.log(value) / Math.log(1024))
            var sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po'];
            return (value / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
          case 'time':
            return moment(value).format('DD/MM/YYYY');
          case 'timestamp':
            return moment.unix(value/1000).format('DD/MM/YYYY');
          default:
            return value;
        }
      }
    },
    computed: {
      markerStyle: function () {
        this.markerStatus.color_ext
        return this.width;
      },
      cssVars() {
        if(this.markerClass === "fad fa-map-marker"){
          return {
            '--fa-primary-color': this.colorExt,
            '--fa-secondary-color': this.colorInt
          }
        }
        else {
          return {
            '--fa-primary-color': this.colorInt,
            '--fa-secondary-color': this.colorExt
          }
        }
      },
    },
    created() {
      // this.svg = require(`resources/assets/images/svg/${this.name}.svg`);
    },
    mounted: function () {
      this.$nextTick(function () {
        this.listFilteredTypes(this.$refs.selectType.value)
      })
    },
    watch: {
      newMarker: function(newMarker) {
        console.log(newMarker)
        if (Object.keys(newMarker).length === 0){
          this.dbId = "";
          this.coord = "";
          this.revitId = "";
          this.objectName = "";
        }
        else {
          this.dbId = newMarker.dbId;
          this.coord = `
            x:${newMarker.attachmentPoint.x}
            y:${newMarker.attachmentPoint.y}
            z:${newMarker.attachmentPoint.z}`;
          let itemName = newMarker.properties.name;
          if (itemName.indexOf(' [') > -1){
            let names = itemName.split(' [');
            itemName = names[0];
            this.revitId = names[1].replace(']','')
          }
          this.objectName = itemName;
        }
      }
    },
    methods:{
      listFilteredTypes(type){
        this.markerByType = markersTypes[type];
        this.markerType = type;
        setTimeout(function(){
          this.$refs.selectStatus.value = this.$refs.selectStatus.options[0].value;
          let status = this.markerStatus = this.$refs.selectStatus.value;
          console.log(status)
          this.markerClass = this.markerByType[status].font;
          this.colorExt = this.markerByType[status].color_ext;
          this.colorInt = this.markerByType[status].color_int;
        }.bind(this),50)
      },
      listFilteredStatus(status){
        this.markerStatus = status;
        this.markerClass = this.markerByType[status].font;
        this.colorExt = this.markerByType[status].color_ext;
        this.colorInt = this.markerByType[status].color_int;
      },
      addMarker(drawType) {
        console.log(this.select)
        Markers.removeMarker();
        this.select[drawType]= !this.select[drawType];
        if(this.select[drawType] === false){
          return;
        }
        else {
          this.drawType = drawType;
          
          let that = this;
          this.markerItem.type = this.markerType;
          this.markerItem.status = this.markerStatus;

          if (drawType === "marker"){
            Markers.createMarkUp(this.markerItem, function(newMarker){
              if(newMarker){
                that.select[drawType]= !that.select[drawType];
                console.log(newMarker)
                that.newMarker = newMarker;
                Object.assign(that.markerItem, that.newMarker);
              }
            });
          }
          if (drawType === "zone"){
            that.select[drawType]= !that.select[drawType];
            this.modalShow = true;
            this.modalTitle = "Repèrage en Zone";
            this.modalBt = "Ok";
            this.modalContent = `
            <img src="images/blueprint-icon-24.jpg">
            <div id="rDrop" class="resumable-drop">
              En cours de Dev - Stay Tuned !
            </div>
            <div class="resumable-progress upload_hide">
              Fonction pour le mode vue 2D
            </div>`;
          }
        }
      },
      saveMarker(markerItem) {
        if(markerItem.located){
          Markers._currentMarkUp = undefined;
          this.$emit("panelCreateShow", false)
          this.$root.$data.markersItemsAll.push(markerItem)
        }
      },
      pictureRandom(){
        let pictureRandom = Math.floor(Math.random() * (50 - 1) + 1);
        let pictureUrl = "photos/"+pictureRandom+".jpg";
        // this.pictureUrl = pictureUrl;
        return pictureUrl
      }
    }
  }
</script>

<style>
#markupCreate {
  height: 100%;
  overflow: auto;
  position: relative;
}
.panel_header i {
  padding-right: 5px;
}
.panel_title_marker {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
}
.panel_item {
  background-color: white;
  padding: 5px;
  margin: 5px;
}
.panel_item_title {
  font-weight: 700;
}
.panel_item_content {
  padding-top: 10px;
}
.bts_draw {
  display: flex;
  align-items: center;
}
.bts_draw > i {
  padding-left: 20px; 
}
.bt_save {
  float: right;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}
.marker_selection {
  display: flex;
  justify-content: space-around;
}
.marker_selection select {
  width: 100px;
  border: 1px solid #d2d2d2;
  font-size: 12px;
  font-family: Lato;
}
.marker_data {
 display: flex;
}
.marker_picture {
  width: 100px;
  height: auto;
  text-align: center;
}
.marker_comment {
  margin-left: 5px;
}
.markers2D.svg_container{
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
}
.markers2D .circle{
  cursor: pointer;
}
.marker_ic:hover {
  color: blue;
  cursor: pointer;
}
.marker_ic.selected {
  color: blue;
}
.marker_infos {
  padding-left: 15px;
  text-align: left;
}
.param {
  display: flex;
  font-size: 12px;
  padding-bottom: 3px;
  line-height: 24px;
  width: 100%;
}
.paramLabel {
  /* min-width: 80px; */
  /* max-width: 80px; */
  flex-grow: 1;
  /* font-weight: 700; */
}
.paramValue {
  /* width: 100%; */
  /* padding: 0 10px 0 0; */
  text-align: right;
}
.modal_bt {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 12px;
  padding: 0px 10px;
  border-radius: 3px;
  background-color: #eaeaea;
  cursor: pointer;
  border: 1px solid #eaeaea;
}
.modal_input {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  width: 100%;
}
.modal_footer {
  padding: 20px 20px;
  float: right;
}
</style>