/* eslint-disable no-mixed-spaces-and-tabs */
/**
 * @fileoverview DB-LAB Forge 2D SVG Markups object class
 *
 * @author Mathieu Lalanne
 * Copyright 2019 Mathieu Lalanne
 */

import Snap from "snapsvg-cjs";
import ViewerToolkit from "../../vendor/Viewer.Toolkit.js"
export default ViewerToolkit
let markersTypes = require("./viewerMarkersTypes.js").markersTypes;

/**
 * @namespace
 */
if(window["Markers"] === undefined) window.Markers = {};

Markers.initSvg = function(viewers){
	viewers.forEach(function(_viewer){
		let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		svg.setAttribute("class", "markers2D svg_container");
		svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");
		document.body.appendChild(svg);
		// let svgContainer = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		_viewer.container.appendChild(svg);
		Markers.svgContainer = svg;
		_viewer.svgDiv = true;
		let overlay = Snap(svg);
		Markers.overlay = overlay;

		_viewer.addEventListener(
			Autodesk.Viewing.EXPLODE_CHANGE_EVENT,
			Markers.onExplode);

		_viewer.addEventListener(
			Autodesk.Viewing.VIEWER_RESIZE_EVENT,
			Markers.onResize);

		_viewer.addEventListener(
			Autodesk.Viewing.CAMERA_CHANGE_EVENT,
			Markers.onCameraChanged);
	})
	Markers._markUps = {};
};

Markers.removeMarker = function (){
	if (Markers._currentMarkUp !== undefined){
		Markers.deleteMarkUp(Markers._currentMarkUp);
		Markers._currentMarkUp = undefined;
	}
	document.removeEventListener("mousemove", Markers.onMouseMove);
	let canvasWrap = document.querySelectorAll(".canvas-wrap");
	canvasWrap.forEach(function(el){
		el.style.cursor = "none";
		el.lastChild.style.pointerEvents = "initial";
	});
};

Markers.removeAllMarkers = function (){
	Markers._markUps = {};
	Markers.overlay.selectAll("g").remove();
};

Markers.updateMarkers2d = function(markers, callback){
	if (!Markers.overlay) return;
	let groups = Markers.overlay.selectAll("g");
	if(markers.length > 0){
	  viewer.clearSelection();
	  markers.forEach((node) =>{
			let group = Snap("#G_"+node.guid);
			if (group){
				group.attr({ display : "block" });
				groups.exclude(group);
			}
	  });
	  groups.forEach(function(el) {
			el.attr({ display : "none" });
	  });
	}
	else {
	  groups.forEach(function(el) {
			el.attr({ display : "none" });
	  });
	}
}

Markers.highlight2DMarkup = function(node, callback){
	let groups = this.overlay.selectAll("g");
	if(node){
	  if(Snap("#G_"+node.guid)){
			let group = Snap("#G_"+node.guid);
			if (group){
				group.attr({ opacity: "" });
				groups.exclude(group);
				viewer.select([node.dbId]);
			}
			groups.forEach(function(el) {
				el.attr({ opacity: "0.1" });
			});
	  }
	  else {
			groups.forEach(function(el) {
		  el.attr({ opacity: "0.1" });
			});
	  }
	}
	else {
	  groups.forEach(function(el) {
			el.attr({ opacity: "" });
	  });
	  viewer.select([]);
	}
	if (callback){
	  callback();
	}
}

Markers.getAllDbIds = function(_viewer) {
	let instanceTree = _viewer.model.getData().instanceTree;
	let allDbIdsStr = Object.keys(instanceTree.nodeAccess.dbIdToIndex);
	return allDbIdsStr.filter(validId);
	function validId(id) {
		let name = instanceTree.getNodeName(id);
		if (name !== "Body"){
			return id
		}
	}
	// return allDbIdsStr.map(function(id) { 
	// 	let name = instanceTree.getNodeName(id);
	// 	console.log(name)
	// 	if (name !== "Body"){
	// 		return parseInt(id)
	// 	}
	// });
}

Markers.loadMarker = async function(markItem, mode, allDbId, callback){
	
	if(mode === "random"){
		
		let dbId= parseInt(allDbId[Math.floor(Math.random()*allDbId.length)]);
		markItem.dbId = dbId;
		// let getProps = function(dbId){
		// 	return new Promise( async (resolve, reject)=> {
		// 		viewer.model.getProperties(parseInt(dbId), (result)=>{
		// 			// if (result.name.startsWith("Body")) {
		// 			// 	let instanceTree = viewer.model.getData().instanceTree;
		// 			// 	let ndbId = instanceTree.getNodeParentId(dbId);
		// 			// 	dbId = ndbId;
		// 			// 	getProps (dbId);
		// 			// }
		// 			// else {
		// 				resolve(result);
		// 			// }
		// 		});
		// 	});
		// }
		viewer.model.getProperties(parseInt(dbId), (result)=>{
		// getProps(dbId).then(function(result){
			// let result = await Promise.all(dbId)
			// let result = await getProps(dbId)
			let itemName = result.name;
			console.log(result)
			if (itemName.indexOf(' [') > -1){
				let names = itemName.split(' [');
				itemName = names[0];
				markItem.revitId = names[1].replace(']','')
			}
			markItem.objectName = itemName;
			ViewerToolkit.getFragIds(viewer.activeModel, dbId).then(function(fragId){
				markItem.fragId = fragId;
				ViewerToolkit.getWorldBoundingBox(viewer.activeModel, dbId).then(function(boundingBox){
					let objectCenter = new THREE.Vector3();
					objectCenter.x = (boundingBox.max.x + boundingBox.min.x) / 2;
					objectCenter.y = (boundingBox.max.y + boundingBox.min.y) / 2;
					objectCenter.z = (boundingBox.max.z + boundingBox.min.z) / 2;
					markItem.attachmentPoint = objectCenter;
					markItem.located = true;
					console.log(markItem)
					Markers.newMarkUp(markItem, dbId, fragId[0], callback).then(function(marker){
						// console.log(marker)
						Markers._markUps[marker.divId] = marker;
						Markers.updateMarkUp(marker);
					});
				});
			});
		})
		// });
	}

}
Markers.createMarkUp = function (markItem, callback){
	let _self = this;
	let _viewer;
	let selection;
	let dbId;
	let fragId;
	let model;
	let properties;

	if (Markers._currentMarkUp){
		Markers.deleteMarkUp(Markers._currentMarkUp);
		Markers._currentMarkUp = "undefined";
	}

	document.addEventListener("mousemove", Markers.onMouseMove);

	let addMarker = function(event){
		if(event.selections && event.selections.length){
			console.log(event)
			_viewer = event.target;
			selection = event.selections[0];
			model = selection.model;
			dbId = selection.dbIdArray[0];
			fragId = selection.fragIdsArray[0];
		}
		if (typeof dbId !== "undefined") {
			if (Array.isArray(fragId)){
				fragId = fragId[0];
			}
			model.getProperties(parseInt(dbId), (result)=>{
				properties = result;
				if (result.name.startsWith("Body")) {
					let instanceTree = model.getData().instanceTree;
					let ndbId = instanceTree.getNodeParentId(dbId);
					dbId = ndbId;
				}

				// Markers._currentMarkUp = Markers.newMarkUp(dbId, fragId);
				Markers.newMarkUp(markItem, dbId, fragId).then(function(_currentMarkUp){
					Markers._currentMarkUp = _currentMarkUp;
					Markers._markUps[Markers._currentMarkUp.divId] = Markers._currentMarkUp;

					if (Markers._currentMarkUp !== "undefined"){
						let screenPoint = {
							x: event.target.toolController.lastClickX,
							y: event.target.toolController.lastClickY
						};
						let n = Markers.normalizeCoords(screenPoint);
						let hitPoint = _viewer.utilities.getHitPoint(n.x, n.y);
						console.log(hitPoint);
						if (hitPoint !== null) {
							let markUp = Markers._currentMarkUp;
							markUp.attachmentPoint = hitPoint;
							markUp.located = true;
							markUp.properties = properties;
							console.log(markUp);
							Markers.updateMarkUp(markUp);
						
							viewer.addEventListener(
								Autodesk.Viewing.EXPLODE_CHANGE_EVENT,
								Markers.onExplode);
				
							viewer.addEventListener(
								Autodesk.Viewing.VIEWER_RESIZE_EVENT,
								Markers.onResize);
				
							viewer.addEventListener(
								Autodesk.Viewing.CAMERA_CHANGE_EVENT,
								Markers.onCameraChanged);

							setTimeout(function(){
								document.removeEventListener("mousemove", Markers.onMouseMove);
								let canvasWrap = document.querySelectorAll(".canvas-wrap");
								canvasWrap.forEach(function(el){
									el.style.cursor = "none";
									el.lastChild.style.pointerEvents = "initial";
								});
								viewers.forEach(function(_viewer){
									_viewer.removeEventListener(
										Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
										addMarker);
								})
							}, 200);
							callback(markUp);
						}
					}
				})
				
			});
		}
	}

	viewer.addEventListener(
		Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
		addMarker)
	// viewerN.addEventListener(
	// 	Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
	// 	addMarker)
};

Markers.newMarkUp = function (markItem, dbId, fragId, callback) {
	return new Promise((resolve, reject) => {
		let _self = this;
		const divId = ViewerToolkit.guid();
		markItem.guid = divId;

		let markerIconUrl = markersTypes[markItem.type][markItem.status]["icon"];
		let color_ext = markersTypes[markItem.type][markItem.status]["color_ext"];
		let color_int = markersTypes[markItem.type][markItem.status]["color_int"];

		// let connector = Markers.overlay.circle(0, 0, 20.0);
		Snap.load(markerIconUrl, function(f) {
			// _self.overlay.append(f);
			let connector = _self.overlay.g();
			connector.attr({id: "G_"+divId});
			let paths = f.selectAll("path");
			paths.forEach(function(el) {
				// el.transform( "s0.1,0.1");
				connector.append(el);
				let classEl = el.attr("class");
				if(classEl === "fa-primary"){
					el.attr("fill", color_int);
				}
				if(classEl === "fa-secondary"){
					el.attr("fill", color_ext);
				}
			});
			
			// connector.transform( "s0.1,0.1");
			const X = -15;
			const Y = -40;
			connector.transform("t"+ X +","+ Y);

			let attachmentPoint = null;
			if(markItem.attachmentPoint){
				attachmentPoint = markItem.attachmentPoint;
			}
			

			// connector.attr("fill", "rgb(80, 92, 105)");
			// connector.attr("stroke", "white");
			// connector.attr("stroke-width", "3");
			connector.node.style.pointerEvents = "all";
			// connector.node.id = 'c_'+ divId;

			connector.hover(function(){
				connector.node.style.cursor = "pointer";
			});
		
			connector.click(function(){
				if (connector.hasClass('markupSelected')){
					// var selectedBefore = document.querySelector('.active');
					// console.log
					// if (selectedBefore){
					//   selectedBefore.classList.remove('active');
					// }
					connector.removeClass('markupSelected');
					var groups = Markers.overlay.selectAll('g');
					groups.exclude(connector);
					groups.forEach(function(el) {
					  el.attr({ opacity: '' });
					});
					viewer.clearSelection();
					
					return callback(markItem);
				}
				else {
					var mSelectedBefore = document.querySelector('.markupSelected');
					if (mSelectedBefore){
					  mSelectedBefore.classList.remove('markupSelected');
					}
					
					// var selectedBefore = document.querySelector('.active');
					// if (selectedBefore){
					//   selectedBefore.classList.remove('active');
					// }
					// var elt = document.getElementById(node.guid);
					// elt.classList.add('active');
					
					connector.addClass('markupSelected');
					connector.attr({ opacity: '' });
					var groups = _self.overlay.selectAll('g');
					groups.exclude(connector);
					groups.forEach(function(el) {
					  el.attr({ opacity: '0.1' });
					});
					return callback(markItem);
				}
			});

			let markUp = {
				dbId: dbId,
				fragId: fragId,
				//            line: path,
				divId: divId,
				//            textPos: null,
				connector: connector,
				// number: text,
				attachmentPoint: attachmentPoint,
			//			screenPoint: null
			//            position: _self.getMeshPosition(fragId)
			};
			// markUp.attachmentPoint = hitPoint;
			// console.log(markUp);
			return resolve(markUp);
		});
	});
};

Markers.updateMarkUp = function(markUp) {
	let _self = this;
	let attachmentPoint = {
		x: markUp.attachmentPoint.x,
		y: markUp.attachmentPoint.y,
		z: markUp.attachmentPoint.z
	};
	
	let screenPoint = Markers.worldToScreen( attachmentPoint, viewer.getCamera());
	// let offset = _self.getClientOffset(viewer.container);
	
	markUp.screenPoint = screenPoint;
	
	// <g transform="translate(offset_x,offset_y)"></g>
	// markUp.connector.selectAll("path").forEach(function(el){
	// 	el.attr({
	// d: "M "+screenPoint.x +","+ screenPoint.y
	// });
	// });
	// markUp.connector.attr({
	// 	transform: "translate("+screenPoint.x, screenPoint.y)
	// });

	const X = -15 + screenPoint.x;
	const Y = -40 + screenPoint.y;
	markUp.connector.transform("t"+X+","+Y);

	// markUp.connector.attr({
	// 	cx: screenPoint.x,
	// 	cy: screenPoint.y
	// });
};

Markers.updateMarkUps = function() {
	for(let key in Markers._markUps){
		Markers.updateMarkUp(Markers._markUps[key]);
	}
}

Markers.deleteMarkUp = function(markUp) {
	if (markUp){
		markUp.connector.remove();
		markUp = {};
		delete Markers._markUps[markUp.divId];
	}
};

Markers.normalizeCoords = function(screenPoint) {
	let viewport = viewer.navigation.getScreenViewport();
	let n = {
		x: (screenPoint.x - viewport.left) / viewport.width,
		y: (screenPoint.y - viewport.top) / viewport.height
	};
	return n;
};

Markers.worldToScreen = function(worldPoint, camera) {

	let p = new THREE.Vector4();

	p.x = worldPoint.x;
	p.y = worldPoint.y;
	p.z = worldPoint.z;
	p.w = 1;

	p.applyMatrix4(camera.matrixWorldInverse);
	p.applyMatrix4(camera.projectionMatrix);

	// Don't want to mirror values with negative z (behind camera)
	// if camera is inside the bounding box,
	// better to throw markers to the screen sides.
	if (p.w > 0)
	{
		p.x /= p.w;
		p.y /= p.w;
		p.z /= p.w;
	}

	// This one is multiplying by width/2 and height/2,
	// and offsetting by canvas location
	let point = viewer.impl.viewportToClient(p.x, p.y);

	// snap to the center of the pixel
	point.x = Math.floor(point.x) + 0.5;
	point.y = Math.floor(point.y) + 0.5;

	return point;
};

Markers.onMouseMove = function () {
	let canvasWrap = document.querySelectorAll(".canvas-wrap");
	canvasWrap.forEach(function(el){
		// el.style.cursor = "crosshair";
		el.style.cursor = "url('images/map-marker-light_10.cur') 15 40, auto";
		el.lastChild.style.pointerEvents = "none";
	});
};

Markers.onCameraChanged = function(event) {
	Markers.updateMarkUps();
};

Markers.onExplode = function(event) {
	Markers.updateMarkUps();
};

Markers.onResize = function(event) {
	Markers.updateMarkUps();
};