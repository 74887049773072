"use strict";

require("es6-promise/auto");

import Vue from "vue";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

import Filters from "./core/filters";
import VueI18Next from "./core/i18next.js";
import VueWebsocket from "vue-websocket";

import App from "./App";

Vue.use(Filters);
Vue.use(VueWebsocket);

Vue.prototype.$helper = require("../tools/forgeApiHelper.js").helper;

// Register i18next localization module. We need to 
// wait it before start the application!
Vue.use(VueI18Next, (i18next) => {
	let router = require("./core/router").default; // Load only after i18next initialized

	new Vue({
		el: "#app",
		components: {
			App
		},
		router,
		render: h => h("app")
	});
});
