"use strict";


let axios = require("axios");

let forgeOpenApi = require("../api/fileserver.json");


let jwt = require("jsonwebtoken");


let helper = {};
helper.server = forgeOpenApi.servers.url;

//TODO: indicate Root path in configs and create it on launching app if doesn't exist
helper.root = ROOT_NAME;

helper.tokenName =   "dblabToken";
/**
 * @return {boolean}
 */
helper.isTokenExpired = function(token) {
	let isExpired = true;
	// const token = localStorage.getItem(aToken);
	if (token != null && token != "") {
		let decodedToken = jwt.decode(token, {complete: true});
		let dateNow = new Date();
		if (decodedToken !=  null && decodedToken.payload.exp * 1000 < dateNow.getTime()) {
			isExpired = true;
		}
		else {
			isExpired = false;
		}
	}
	return isExpired;
};

helper.getToken = function(aToken) {
	return new Promise((resolve, reject) => {
		if (this.isTokenExpired(aToken)) {
			let error = "invalidToken";
			reject(error);
		} else {
			return resolve(aToken);
		}
	});
};

helper.checkAuth = function() {
	return new Promise((resolve, reject) => {
		let hash = location.hash.replace('#/','');
		let hashElements = hash.split(",");
		const token = hashElements[2];
		// const token = localStorage.getItem(this.tokenName);
		if (token != null && token != "") {
			helper.getToken(token).then(function (authToken) {
				resolve();
			}).catch((error) => {
				reject();
			});
		}
		else {
			reject();
		}
	});
};


helper.authenticate =  function(username, password) {
	return new Promise((resolve, reject) => {
		const data = JSON.stringify({
			"username": username,
			"password": password,
		}
		);
		let xhr = new XMLHttpRequest();
		xhr.withCredentials = false;

		let tokenName = this.tokenName;
		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				console.log(this.responseText);
				let newToken = JSON.parse(this.responseText).token;
				if (newToken === null){
					reject();
				}
				localStorage.setItem(tokenName,newToken);
				resolve(newToken);
			}
		});

		xhr.open("POST", FS_PUBLIC_URL +"/authenticate");
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("cache-control", "no-cache");
		xhr.setRequestHeader("Postman-Token", "2ab151a3-dcc4-48ce-9cc8-68605ada8bc2");

		xhr.send(data);
	});
};



export {helper};