<template>
  <div id="login">
    <div class="login_visual"></div>
    <div class="login_panel">
      <app-logo class="logo_over"></app-logo>
      <form id="signinForm" action="/login" method="post">
        <div id="inputForm">
          <div class="inputblock">
            <input type="text" v-model="username" name="login" class="textinput" :placeholder='text.name' autofocus="autofocus">
          </div>
          <div class="inputblock">
            <input name="password" v-model="password" class="textinput" type="password" :placeholder="text.password">
          </div>
        </div>
        <span id="errormsg">{{errorMessage}}</span>
        <button id="connexion" @click.prevent="submit()" rel="authentication" class="noimg clickable" :title="text.connexion">{{text.connection}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import logo from './header/logo.vue';

export default {
  name: 'app',
  data: function (){
    return {
      text: {
        name: 'Nom',  //TODO: change this to a language lookup 
        password: 'Mot de passe',
        connection: 'Connexion',
        signOut: 'Déconnectez',
        forgot: 'Oublié votre mot de passe ?',
        incorrectLogin: 'Login ou mot de passe invalide'
      },
      username:'',
      password:'',
      errorMessage: ''
    }
  },
  components: {
    appLogo: logo
  },
  mounted: function (){

  },
  methods: {
    submit (){
      console.log('Attempting Admin Login ...')
      this.$helper.authenticate(this.username, this.password).then((success,failure) =>{
        if(success){
          this.errorMessage = '';//clear, just in case
          this.$router.push('console');
        }
      }).catch(err=>{
        console.log(`Failed login attempt-> User:${this.username}, Pass:${this.password}`);
        this.errorMessage = this.text.incorrectLogin;
      })
    }
  }
}
</script>

<style>
input:focus{
  outline:none;
}
#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.login_visual {
  width: 50%;
  height: 100%;
  background-image: url("../assets/images/Batinbox-background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover;
  border-right: 1px solid #aaaaaa; 
}
.login_panel {
  width: 50%;
  height: 50%;
  text-align: center;
}

#index {
  position: relative;
  box-shadow: 0px 0px 12px #232323;
  text-align: center;
  width: 360px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  padding-bottom: 15px;
  padding-top: 60px;
}

.logo {
  font-size: 24px;
  font-weight: 300;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
  color: white;
  display: inline;
  cursor: pointer;
}

#logo {
  display: block;
  margin: 0 auto;
  width: 350px;
}

.title {
  margin-top: 40px;
/*  font-family: Open sans;*/
  font-size: 20px;
  font-weight: 700;
}
#errormsg {
  display: block;
  height: 40px;
}

#signinForm {
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 300px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
#inputForm {
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
}

.inputblock input {
  font-size: 15px;
  border: 0 none;
  margin: 0;
  padding: 11px 10px 11px 13px;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #96969629;
}

.clickable {
  cursor: pointer;
}

#password {
  font-size: 15px;
  border: 0 none;
  border-top: 1px solid #9a9797;
  margin: 0;
  padding: 11px 10px 11px 13px;
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
}

.inputblock {
  position: relative;
}

#connexion {
  font-size: 15px;
  border: 0 none;
  border-radius: 4px;
  padding: 11px 10px 11px 13px;
  width: 100%;
  background-color: #24a49f;
  color: #ffffff;
}
</style>