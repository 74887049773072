"use strict";

let helper = require("../../tools/forgeApiHelper").helper;

import Vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login.vue";

import viewer from '../components/viewer.vue';

Vue.use(VueRouter);

const checkAuthentication = async (to, from, next) => {
	helper.checkAuth().then(function (authToken) {
		next();
	}).catch((error) => {
		next("/login");
	});
};


export default new VueRouter({
	// mode: "hash",
	mode: 'history', // TODO: choose mode, good one
	routes: [
		{
			path: "/login",
			name: "login",
			component: login
		},
		{
			path: "/",
			name: "viewer",
			component: viewer,
			beforeEnter: checkAuthentication
		},

		{path: '*', redirect: '/'}  //default fallback
	]
});