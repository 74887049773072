<template>
  <div id="app">
    <div class="flex_container" v-bind:style="{height: heightValue}">
      <router-view class="flex_principal" :key="$route.fullPath" @panel="panel = $event" @name="name = $event" v-bind:style="{height: heightValue}"></router-view>
      <transition name="slide-fade">
        <app-panel v-if="panel" id="rightPanel"></app-panel>
      </transition>
    </div>
  </div>
</template>

<script>
import sidePanel from './components/panels/sidePanel.vue';


export default {
  name: 'app',
  data: function(){
    return {
      name: '',
      heightValue: "100%",
      panel: false
    }
  },
  components: {
    appPanel: sidePanel
  },
  watch: {
  },
  created: function(){
    this.$root.$data.display = {
      viewer3d: false,
      viewer2d: false,
      panel: false,
      marker: false,
      list: true
    };
    this.$root.$data.noViewer = {
      viewer3d: false,
      viewer2d: false
    };
    this.$root.$data.viewers = [];
    this.$root.$data.markersItemsAll = [];
  },
  mounted: function (){
    this.heightViewer();
    window.addEventListener('resize', this.heightViewer);
    window.addEventListener("hashchange",this.heightViewer);
    this.$nextTick(function () {
    });
  },
  methods:{
    heightViewer(event){
      var mainToolbarH = document.getElementById('mainToolbar') ? document.getElementById('mainToolbar').offsetHeight : 0;
      var H = window.innerHeight - mainToolbarH;
      this.heightValue = H +'px';
    }
  }
}
</script>

<style>
body, html {height: 100%; font-size: 100%; padding: 0; margin: 0;}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after {
  display: block;
}
#app {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
#rightPanel {
  width: 300px;
  overflow-wrap: break-word;
  border-left: 1px solid #aaaaaa;
}
.flex_container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  background-color: rgb(239, 239, 239);
}
.flex_principal {
  flex-grow: 1;
  display: flex;
}
.slide-fade-enter-active {
  transition: all .6s ease;
}
.slide-fade-leave-active {
  transition: all .6s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(300px);
  opacity: 0;
}
</style>
