<template>
  <div id="viewers" ref="viewers">
    <div id="viewer3d" ref="viewer3d" :class="{hideItem: !viewerdisplay.viewer3d}">
      <div v-if="!noViewer.viewer3d" class="message_viewer2d">
        <div class="message_viewer2d_box">
          <img src="../assets/images/blueprint-icon-24.jpg">
          <div class="message_viewer2d_txt">Aucun document 3D</div>
        </div>
      </div>
    </div>
    <div id="viewer2d" :class="{hideItem: !viewerdisplay.viewer2d}">
      <select v-if="pathCollection.length > 0" @change="getSheet($event.target.value)" class="viewer2d_select">
        <option v-for="(item, i) in pathCollection" :key="i" :value="item.path">{{item.name || "Vue " + (i + 1)}}</option>
      </select>
      <div v-else class="message_viewer2d">
        <div class="message_viewer2d_box">
          <img src="../assets/images/blueprint-icon-24.jpg">
          <div class="message_viewer2d_txt">Aucun document 2D</div>
        </div>
      </div>
      <div ref="viewer2dcontainer">
      </div>
    </div>
    <div v-if="!viewerdisplay.viewer3d && !viewerdisplay.viewer2d" class="message_viewer2d">
      <div class="message_viewer2d_box">
        <img src="../assets/images/Batinbox_logo.png" style="width:200px;">
        <div class="message_viewer2d_txt">Visionneuse BIM</div>
      </div>
    </div>
    <div class="icons_viewer">
      <div class="item" @click="panelMenu('viewer3d')" :class="{selected: viewerdisplay.viewer3d, noViewer: noViewer.viewer3d}">3D</div>
      <div class="item" @click="panelMenu('viewer2d')" :class="{selected: viewerdisplay.viewer2d, noViewer: noViewer.viewer2d}">2D</div>
    </div>
    <div class="icon_panel_viewer"><i class="fa fa-bars fa-lg"></i></div>
  </div>
</template>

<script>
require('../../scripts/viewer/viewerForge.js');
let helper = require('../../tools/forgeApiHelper.js');
const base64url = require('base64-url');

export default {
  name: 'viewers',
  data: function (){
    return {
      heightValue: "100%",
      pathCollection: [],
      viewerdisplay: {},
      noViewer: this.$root.$data.noViewer
    }
  },
  components: {
  },
  mounted: function (){
    this.viewerdisplay = this.$root.$data.display;
    // this.heightViewer();
    // window.addEventListener('resize', this.heightViewer);
    // window.addEventListener("hashchange",this.heightViewer);
    this.readHash();
    this.$nextTick(function () {
      
    });
  },
  watch: {
  },
  methods:{
    readHash(){
      var that = this;
      if (this.$route.hash.length > 0){
        let hash = this.$route.hash.replace('#/','');
        let hashElements = hash.split(",");
        let hashName = hashElements[1];
        let hashModelId = hashElements[0];
        let token = hashElements[2];

        localStorage.setItem(helper.tokenName, token);

        if (hashElements.length !== 3){
          that.viewerdisplay.viewer3d = false;
          that.viewerdisplay.viewer2d = false;
          that.$root.$data.display = that.viewerdisplay;
          return;
        }

        //Global viewers Array
        window.viewers = [];

        Forge.getPaths(hashModelId).then((paths) => {
          console.log(paths)
          if(paths.path3D.length > 0){
            that.viewerdisplay.viewer3d = true;
            that.$root.$data.display = that.viewerdisplay;
            that.$root.$data.noViewer.viewer3d = false;
            that.noViewer.viewer3d = false;
            that.launchViewer(paths.path3D[0].path, hashName);
          }
          if(paths.path2D.length > 0){
            that.viewerdisplay.viewer2d = true;
            that.$root.$data.display = that.viewerdisplay;
            that.$root.$data.noViewer.viewer2d = false;
            that.noViewer.viewer2d = false;
            that.pathCollection = paths.path2D;
            let localPath = paths.path2D[0].path;
            Forge.sheetViewer(that.$refs.viewer2dcontainer, localPath, false)
          }
        }, (error) => {
          console.log("No viewables !")
          that.viewerdisplay.viewer3d = false;
          that.viewerdisplay.viewer2d = false;
          that.$root.$data.display = that.viewerdisplay;
        });
      }
      else {
      }
      
    },
    viewersResize(){
      window.viewers.forEach(function(_viewer){
        _viewer.resize('', '', true);
      });
    },
    getSheet(value){
      Forge.sheetViewerLoad(value)
    },
    async launchViewer(path, hashName){
      let that = this;
      //Check if svf is online
      if (path.includes("urn:")) {
        Forge.loadModelSingle(this.$refs.viewer3d, path, hashName, true)
      }
      else {
        Forge.loadModelSingle(this.$refs.viewer3d, path, hashName, false)
      }
    },
    heightViewer(event){
      var mainToolbarH = document.getElementById('mainToolbar') ? document.getElementById('mainToolbar').offsetHeight : 0;
      var H = window.innerHeight - mainToolbarH;
      this.heightValue = H +'px';
    },
    panelVisibility(){
      let that = this;
      this.$root.$data.display.panel ? this.$root.$data.display.panel === false : this.$root.$data.display.panel === true;
      that.viewerdisplay.panel = !that.viewerdisplay.panel;
      this.$emit('panel', that.viewerdisplay.panel)
      setTimeout(function(){
        that.viewersResize();
      }, 800)
    },
    panelMenu(type){
      let that = this;
      this.viewerdisplay[type]= !this.viewerdisplay[type];
      if (type === "viewer3d" || type === "viewer2d"){
        console.log('resize !')
        setTimeout(() => {
          that.viewersResize();
        }, 300);
      }
    }
  }
}
</script>

<style>
#viewers {
  position: relative;
} 
#viewer3d, #viewer2d {
  width: 100%;
  position: relative;
  height: 100%;
}
#viewer2d {
  border-left: 1px solid #aaaaaa;
}
.viewer2d_select {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  height: 30px;
  min-width: 100px;
  border-radius: 4px;
  border: 1px solid rgb(219, 219, 219);
}
.hideItem {
  /* visibility: hidden; */
  display: none;
}
.message_viewer2d {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  display: flex;
}
.message_viewer2d_box {
  position: relative;
}
.message_viewer2d_txt {
  font-weight: 700;
  font-size: 16px;
  /* position: absolute; */
  bottom: 20px;
  color: #50a5a0;
  width: 100%;
  text-align: center;
}
.logo_viewer {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.logo_viewer img {
  width: 100px;
  height: auto;
}
.title_viewer {
  position: absolute;
  top: 10px;
  left: 120px;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgb(219, 219, 219);
  background-color: white;
}
.title_viewer span {
  /* width: 100px;
  height: auto; */
}
.icons_viewer {
  position: absolute;
  top: 150px;
  right: 10px;
  z-index: 100;
  font-size: 13px;
  line-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #dedede;
}
.icons_viewer > * {
  height: 34px;
  width: 34px;
  text-align: center;
  cursor: pointer;
}
.item {
  background-color: #ffffff;
}
.item.selected {
  background-color: #e4e4e4;
}
.noViewer {
  position: relative;
  overflow: hidden;
}
.noViewer:before {
  border-top: 1px solid #c3c3c3;
  content: '';
  position: absolute;
  top: 0; right: 0; left: -50%;
  transform: rotate(-45deg);
  transform-origin: 100% 0;
}
.icon_panel_viewer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}
/* .adsk-viewing-viewer.light-theme .adsk-control-group {
  box-shadow: none;
  border: 1px solid #aaaaaa;
} */
</style>