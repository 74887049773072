//TODO : Adapt markup infos within Markup bdd call (array / no array)
<template>
  <div id="">
    <!-- <div class="panel_header panel_item panel_title_marker">
      <span>Liste des Marqueurs</span>
    </div> -->
    <div v-if="panelCreateShow" class="panel_item">
      <div @click="panelCreateShow = !panelCreateShow" class="bt_marker">Retour Liste</div>
    </div>
    <app-markup v-if="panelCreateShow" :class="['panelCreate', {panelCreateShow: panelCreateShow}]" v-on:panelCreateShow="panelCreateShow = $event"></app-markup>
    <div v-else>
      <div class="panel_item bts_box">
        <div @click="panelCreateShow = !panelCreateShow" class="bt_marker">Nouveau Marqueur</div>
        <div @click="randomMarkers()" class="bt_marker">Génerer aléatoire</div>
        <input v-model="markersNumber" class="input_random">
      </div>
      <div class="panel_item">
        <div class="panel_item_title">Listes et Status des repérages affichés</div>
        <div class="marker_selection panel_item_content">
          <i class="fad fa-2x marker_ic" :style="cssVars" :class="[markerClass]"></i>
          <select ref="selectType" @change="listFilteredTypes($event.target.value)">
            <option value="*">Toutes listes</option>
            <option v-for="(type, i) in Object.keys(markersTypes)" :key="i" :value="type" :markerByType="markersTypes.type">{{type}}</option>
            <option value="">Aucune</option>
          </select >
          <select v-if="markerType === '*'" ref="selectStatus">
            <option value="*">Tous Status</option>
          </select>
          <select v-else ref="selectStatus" @change="listFilteredStatus($event.target.value)">
            <option value="*">Tous</option>
            <option v-for="(status, i) in Object.keys(markerByType)" :key="i" :value="status">{{markerByType[status].label}}</option>
          </select >
        </div>
      </div>
      <div class="panel_content">
        <div class="panel_item">
          <div class="panel_item_title">Listing des repères</div>
          <div class="bts_box">
            <span style="line-height: 30px;">{{markersItemsDisplay.length}} repère(s)</span>
            <div class="bt_marker" @click="exportBCF()">Export BCF</div>
          </div>
          <div v-if="Array.isArray(markersItemsDisplay) && markersItemsDisplay.length > 0">
            <transition-group name="list" tag="ul">
            <li v-for="(node, i) in markersItemsDisplay" :key="i" :id="node.guid" class="panel_item">
              <div class="param"><label class="">N° de prélèvement</label><span class="paramValue">{{node.number}}</span></div>
              <div class="marker_selection panel_item_content">
                <i :id="'ic_'+node.guid" class="fa-2x" :style="getMarkerStyle(node)" :class="[getMarkerClass(node), 'marker_list_ic', {markerSelected: isActive(node.guid), marker_nolocated: !node.located}]" @click="selectComponent($event.target, node)" @dblclick="zoomObject(node)"></i>
                <div>{{node.type}}</div>
                <div>{{node.statusLabel}}</div>
              </div>
              <div v-if="itemDetails === node.guid">
                <div class="panel_item_content">
                  <div class="panel_item_title">Données associées</div>
                  <div class="param"><label class="paramLabel">Date</label><span class="paramValue">{{node.date}}</span></div>
                  <div class="param"><label class="paramLabel">Auteur</label><span class="paramValue">{{node.user}}</span></div>
                  <div class="marker_data panel_item_content">
                    <div class="">
                      <img :src="node.pictureUrl" class="marker_picture">
                    </div>
                    <div class="marker_comment">
                      <span>{{node.comment}}</span>
                    </div>
                  </div>
                </div>
                <div class="panel_item_content">
                  <div class="panel_item_title">Informations BIM</div>
                  <div class="">
                  <!-- <div class="bt_marker" v-html="checkLocated(node)"><i class="fal fa-map-marker-alt"></i></div> -->
                    <i v-if="node.located" class="fas fa-info-square fa-2x" @click="objectInfo(node.dbId)"></i>
                  </div>
                  <div v-if="drawType === 'marker'">
                    <div class="param"><label class="paramLabel">Nom</label><span class="paramValue">{{node.objectName}}</span></div>
                    <div class="param"><label class="paramLabel">Viewer Id</label><span class="paramValue">{{node.dbId}}</span></div>
                    <div class="param"><label class="paramLabel">Revit Id</label><span class="paramValue">{{node.revitId}}</span></div>
                    <div class="param"><label class="paramLabel">Coord</label><span class="paramValue">{{node.coord}}</span></div>
                  </div>
                  <div v-else class="marker_infos">
                    <div class="param"><label class="paramLabel">Nom</label><span class="paramValue">{{node.objectName}}</span></div>
                    <div class="param"><label class="paramLabel">Viewer Id</label><span class="paramValue">{{node.dbId}}</span></div>
                    <div class="param"><label class="paramLabel">Revit Id</label><span class="paramValue">{{node.revitId}}</span></div>
                    <div class="param"><label class="paramLabel">Surface</label><span class="paramValue">{{node.surface}}</span></div>
                    <div class="param"><label class="paramLabel">Coord</label><span class="paramValue">{{node.coord}}</span></div>
                  </div>
                </div>
              </div>
            </li>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="modalShow" :title="modalTitle" @close="modalShow = false">
      <div slot="modal_content" v-html="modalContent"></div>
      <div slot="modal_footer" class="modal_footer">
      </div>
    </modal>
  </div>
</template>

<script>
  import moment from 'moment';
  import markupPanel from './markupPanel.vue';
  import modal from '../modal.vue';
  require('../../../scripts/viewer/viewerMarkers.js');
  let markersTypes = require('../../../scripts/viewer/viewerMarkersTypes.js').markersTypes;
  let _ = require("lodash");

  export default {
    name: 'App',
    data: function(){
        return{
          select: {
            marker: false,
            zone: false
          },
          panelCreateShow: false,
          markersItemsAll: this.$root.$data.markersItemsAll,
          markersItemsDisplay: [],
          markerItem: {
            id: "1111",
            type: "",
            status: "",
            pictureUrl: "photos/11-O-241.jpg",
            comment:"fdfg fdgdfg yhyjs egrr"
          },
          markersTypes: markersTypes,
          markerByType: "",
          markerType: "",
          markerStatus: "",
          markerClass: "fa-map-marker",
          markersNumber: "10",
          itemDetails: "",
          activeItem: "",
          colorExt: "#51a3d3",
          colorInt: "#cc2a27",
          types:"",
          drawType: "marker",
          dbId:"",
          coord:"",
          objectName: "",
          revitId: "",
          surface: "m²",
          modalShow: false,
          modalTitle: '',
          modalContent: '',
          modalAction: '',
          modalBt: 'Ok',
          selectedItem : '',
          viewer3dState: '',
          viewer2dState: ''
        }
    },
    props: [],
    components:{
      appMarkup: markupPanel,
      modal: modal
    },
    filters: {
      adaptValue: function (value, key) {
        if (!value || value === ''|| value === undefined){ return 'non précisé' }
        switch(key){
          case 'fileSize':
            var i = Math.floor(Math.log(value) / Math.log(1024))
            var sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po'];
            return (value / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
          case 'time':
            return moment(value).format('DD/MM/YYYY');
          case 'timestamp':
            return moment.unix(value/1000).format('DD/MM/YYYY');
          default:
            return value;
        }
      }
    },
    computed: {
      cssVars() {
        if(this.markerClass === "fad fa-map-marker"){
          return {
            '--fa-primary-color': this.colorExt,
            '--fa-secondary-color': this.colorInt
          }
        }
        else {
          return {
            '--fa-primary-color': this.colorInt,
            '--fa-secondary-color': this.colorExt
          }
        }
      },
    },
    created() {
      
    },
    mounted: function () {
      this.$nextTick(function () {
        this.listFilteredTypes(this.$refs.selectType.value)
        // viewer.addEventListener(
        //   Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        //   this.initMarkers); 
      })
    },
    watch: {
      panelCreateShow: function(){
        console.log(this.panelCreateShow)
        if(this.panelCreateShow){
          Markers.updateMarkers2d([]);
        }
        else {
          Markers.updateMarkers2d(this.markersItemsAll)
        }
      }
    },
    methods:{
      getMarkerClass(node){
        let classNode = markersTypes[node.type][node.status].font;
        return classNode;
      },
      getMarkerStyle(node){
        if (markersTypes[node.type][node.status].font === "fad fa-map-marker"){
          return {
            '--fa-primary-color': markersTypes[node.type][node.status].color_ext,
            '--fa-secondary-color': markersTypes[node.type][node.status].color_int
          }
        }
        else {
          return {
            '--fa-primary-color': markersTypes[node.type][node.status].color_int,
            '--fa-secondary-color': markersTypes[node.type][node.status].color_ext
          }
        }
      },
      randomMarkers() {
        this.markersItemsAll = [];
        this.markersItemsDisplay = [];
        let markersList = []
        
        let count = 0;
        let max = this.markersNumber;
        let that = this;
        let randomMarker = function(marker){
          count++;
          marker.number = Math.floor(Math.random() * (that.markersNumber - 1) + 1);
          let keysList = Object.keys(markersTypes);
          let list = keysList[Math.floor(Math.random()*keysList.length)];
          let keysStatus = Object.keys(markersTypes[list]);
          let status = keysStatus[Math.floor(Math.random()*keysStatus.length)];
          let statusLabel = markersTypes[list][status].label;
          marker.type = list;
          marker.status = status;
          marker.statusLabel = statusLabel;
          marker.date = "21 octobre 2019";
          marker.user = "John Doe";
          marker.comment = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer auctor mauris vitae neque euismod dignissim. In laoreet enim sit amet est dictum, id consectetur urna tempor. Mauris eu iaculis ipsum."
          let pictureRandom = Math.floor(Math.random() * (50 - 1) + 1);
          marker.pictureUrl = "photos/"+pictureRandom+".jpg";
          marker.located = false;
          markersList.push(marker);
        }
        while (count < max) {
          let marker = {};
          randomMarker(marker);
        }
        this.markersItemsDisplay = this.markersItemsAll = markersList;
        this.$root.$data.markersItemsAll = this.markersItemsAll;

        //Random witch Markers be located - Use to test
        // let markersToLocate = [];
        // let list = []
        // for(let i= 0; i < markersList.length/2; i++){
        //   let nb = Math.floor(Math.random()*markersList.length)
        //   if(!list.includes(nb)){
        //     list.push(nb)
        //     var item = markersList[nb];
        //     markersToLocate.push(item)
        //   }
        // }
        that.generateMarker(markersList);
      },
      listFilteredTypes(type){
        console.log(type)
        if(type === ""){
          this.markerClass = "";
          this.colorExt = "";
          this.colorInt = "";
          Markers.updateMarkers2d([]);
          this.markersItemsDisplay = [];
          return;
        }
        this.markerType = type;
        this.markerByType = markersTypes[type];

        setTimeout(function(){
          this.$refs.selectStatus.value = this.$refs.selectStatus.options[0].value;
          let status = this.markerStatus = this.$refs.selectStatus.value;
          console.log(status)
          if (status !== "*"){
            this.markerClass = this.markerByType[status].font;
            this.colorExt = this.markerByType[status].color_ext;
            this.colorInt = this.markerByType[status].color_int;
          }
          else {
            this.markerClass = "";
            this.colorExt = "";
            this.colorInt = "";
          }
          console.log(this.markersItemsAll)
          let typeFiltered = this.markerType !== "*" ? _.filter(this.markersItemsAll, { "type": this.markerType })  : this.markersItemsAll;
          let statusFitered = this.markerStatus !== "*" ?  _.filter(typeFiltered, { "status": this.markerStatus })  : typeFiltered;
          
          console.log(statusFitered)
          this.markersItemsDisplay = statusFitered;
          Markers.updateMarkers2d(this.markersItemsDisplay)
        }.bind(this),100)
      },
      listFilteredStatus(status){
        this.markerStatus = status;
        if (status !== "*"){
          this.markerClass = this.markerByType[status].font;
          this.colorExt = this.markerByType[status].color_ext;
          this.colorInt = this.markerByType[status].color_int;
        }
        else {
          this.markerClass = "";
          this.colorExt = "";
          this.colorInt = "";
        }
        console.log(this.markersItemsAll)
        let typeFiltered = this.markerType !== "*" ? _.filter(this.markersItemsAll, { "type": this.markerType })  : this.markersItemsAll;
        let statusFitered = this.markerStatus !== "*" ?  _.filter(typeFiltered, { "status": this.markerStatus })  : typeFiltered;
          
        console.log(statusFitered)
        this.markersItemsDisplay = statusFitered;
        Markers.updateMarkers2d(this.markersItemsDisplay)
      },
      checkLocated(node){
        let located = node.located ? "Modifier" : "Positionner";
        return located;
      },
      isActive: function(guid){
        return this.activeItem === guid;
      },
      selectComponent(elem, node){
        if(this.activeItem === node.guid){
          this.activeItem = '';
          Markers.highlight2DMarkup(null, function(){
            let allMarkersIc = document.querySelectorAll("i.marker_list_ic");
            allMarkersIc.forEach(elt => {
              elt.style.opacity = ""
            });
            this.itemDetails = "";
          }.bind(this));
        }
        else {
          this.activeItem = node.guid;
          Markers.highlight2DMarkup(node, function(){
            let allMarkersIc = document.querySelectorAll("i.marker_list_ic");
            allMarkersIc.forEach(elt => {
              if(elt === elem){
                elt.style.opacity = ""
              }
              else {
                elt.style.opacity = "0.2"
              }
            });
            this.itemDetails = node.guid;
          }.bind(this));
        }
      },
      zoomObject(node) {
        window.viewer.fitToView([node.dbId])
      },
      selectIc(node){
        if(this.activeItem === node.guid){
          this.activeItem = '';
          let allMarkersIc = document.querySelectorAll("i.marker_list_ic");
          allMarkersIc.forEach(elt => {
            elt.style.opacity = ""
          });
          this.itemDetails = "";
        }
        else {
          this.activeItem = node.guid;
          let itemToSelect = document.getElementById(node.guid);
          let icToSelect = document.getElementById('ic_'+node.guid);
          icToSelect.scrollIntoView({behavior: "smooth", block: "end"});
          let allMarkersIc = document.querySelectorAll("i.marker_list_ic");
          allMarkersIc.forEach(elt => {
            if(elt === icToSelect){
              elt.style.opacity = ""
            }
            else {
              elt.style.opacity = "0.2"
            }
          });
          this.itemDetails = node.guid;
          // this.selectItem(itemToSelect, node)
        }
      },
      selectItem(element, node){

      },
      initMarkers(){
        let viewers = [viewer]
        Markers.initSvg(viewers);
      },
      generateMarker(markersList){
        let that = this;
        Markers.removeAllMarkers();
        let allDbId = Markers.getAllDbIds(viewer)
        console.log(allDbId)
        markersList.forEach(element => {
          Markers.loadMarker(element, "random", allDbId, function(markerSelected){
            that.selectIc(markerSelected);
          });
        });
      },
      exportBCF(){
        this.modalShow = true;
        this.modalTitle = "Export BCF";
        this.modalBt = "Ok";
        this.modalContent = `
        <img src="images/bcf-icon.png" class="modal_bcfimg">
        <div>En cours de Dev - Stay Tuned !</div>
        <div>Export en BCFzip</div>`;
      },
      objectInfo(dbId){
        if (this.selectedItem === dbId){
          this.selectedItem = '';
          viewer.select([]);
          window.viewer.restoreState(this.viewer3dState)
          var propertyPanel = viewer.getPropertyPanel(true);
          propertyPanel.setVisible(false);
        }
        else {
          this.selectedItem = dbId;
          this.viewer3dState = window.viewer.getState();
          viewer.select([dbId]);
          window.viewer.fitToView([dbId])
          viewer.getProperties(dbId, (result)=>{
            if(result.properties) {
              var propertyPanel = viewer.getPropertyPanel(true);
              propertyPanel.setProperties(result.properties);
              propertyPanel.setVisible(true);
            }
          });
        }
      }
    }
  }
</script>

<style>
.bt_marker, .marker_list_ic {
  cursor: pointer;
}
.marker_list_ic:hover {
  text-shadow: 1px 1px 3px #272634;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#markupCreate {
  height: 100%;
  overflow: auto;
  position: relative;
}
.panel_header i {
  padding-right: 5px;
}
.panel_title_marker {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
}
.bts_box {
  display: flex;
  justify-content: space-between;
}
/* .bt_newMarker {
  text-align: center;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  background-color: #24a49f;
  font-weight: 700;
  color: white;
  border-radius: 4px;
  cursor: pointer;
} */
.bt_marker {
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 3px;
  background-color: #24a49f;
  font-weight: 700;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.bt_marker:hover {
  box-shadow: 1px 1px 2px 1px rgba(48, 48, 48, 0.2);
}
.panelCreate {
  display: none;
}
.panelCreateShow {
  display: block;
}
.input_random {
  line-height: 28px;
  border-radius: 4px;
}
.panel_item {
  background-color: white;
  padding: 5px;
  margin: 5px;
  border: 1px solid #dadada;
}
.panel_item_title {
  font-weight: 700;
}
.panel_item_content {
  padding-top: 10px;
}
.header_logo {
  display: block;
  margin: auto;
  height: 80px;
}
.marker_selection {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.marker_selection select {
  width: 100px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 12px;
  font-family: Lato;
  height: 30px;
  outline: none;
}
.marker_nolocated {
  position: relative;
  overflow: hidden;
}
.marker_nolocated:before {
  border-top: 1px solid #c3c3c3;
  content: '';
  position: absolute;
  top: 0; right: 0; left: -50%;
  transform: rotate(-45deg);
  transform-origin: 100% 0;
}
.bts_box input {
  width: 30px;
  border: 1px solid #d2d2d2;
  font-size: 12px;
  font-family: Lato;
  padding-left: 3px;
}
.marker_selection div{
  width: 100px;
  /* border-bottom: 1px solid #d2d2d2; */
  font-size: 12px;
  font-family: Lato;
  line-height: 24px;
  padding-left: 3px;
}
.marker_data {
 display: flex;
}
.marker_picture {
  width: 100px;
  height: auto;
  text-align: center;
}
.markers2D.svg_container{
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
}
.markers2D .circle{
  cursor: pointer;
}
.marker_ic:hover {
  color: blue;
  cursor: pointer;
}
.marker_ic.selected {
  color: blue;
}
.marker_infos {
  padding-left: 15px;
  text-align: left;
}
.fa-info-square {
  color: #24a49f;
  float: right;
  cursor: pointer;
}
.param {
  display: flex;
  font-size: 12px;
  padding-bottom: 3px;
  line-height: 24px;
  width: 100%;
}
.paramLabel {
  flex-grow: 1;
  /* font-weight: 700; */
}
.paramValue {
  /* padding: 0 10px; */
}
.modal_bcfimg {
  width: 100px;
  height: auto;
}
</style>