let markersTypes = {
	listA: {
		valid: {
			label: "N1",
			icon: "images/map-marker-alt_30.svg",
			font: "fad fa-map-marker-alt",
			color_ext: "#51a3d3",//blue
			color_int: "#97c53f"//green
		},
		warning: {
			label: "N2",
			icon: "images/map-marker-alt_30.svg",
			font: "fad fa-map-marker-alt",
			color_ext:"#51a3d3",//blue
			color_int:"#ed9321"//orange
		},
		error: {
			label: "N3",
			icon: "images/map-marker-alt_30.svg",
			font: "fad fa-map-marker-alt",
			color_ext: "#51a3d3",//blue
			color_int: "#cc2a27"//red
		},
		negatif: {
			label: "Négatif",
			icon: "images/map-marker-duotone_30a.svg",
			font: "fad fa-map-marker",
			color_ext:"#51a3d3",//blue
			color_int:"#97c53f"//green
		}
	},
	listB: {
		valid: {
			label: "EP",
			icon: "images/map-marker-alt_30.svg",
			font: "fad fa-map-marker-alt",
			color_ext:"#c26bb3",//pink
			color_int:"#97c53f"//green
		},  
		warning: {
			label: "AC1",
			icon: "images/map-marker-alt_30.svg",
			font: "fad fa-map-marker-alt",
			color_ext:"#c26bb3",//pink
			color_int:"#ed9321"//orange
		}, 
		error: {
			label: "AC2",
			icon: "images/map-marker-alt_30.svg",
			font: "fad fa-map-marker-alt",
			color_ext:"#c26bb3",//pink
			color_int:"#cc2a27"//red
		},
		negatif: {
			label: "négatif",
			icon: "images/map-marker-duotone_30a.svg",
			font: "fad fa-map-marker",
			color_ext:"#c26bb3",//pink
			color_int:"#97c53f"//green
		}
	},
	listC: {
		all: {
			label: "tous",
			icon: "images/map-marker-duotone_30a.svg",
			font: "fad fa-map-marker",
			color_ext:"#494749",//grey
			color_int:"#cc2a27"//red
		},
		negatif: {
			label: "négatif",
			icon: "images/map-marker-duotone_30a.svg",
			font: "fad fa-map-marker",
			color_ext:"#494749",//grey
			color_int:"#97c53f"//green
		},
	}
};

module.exports = {
	markersTypes: markersTypes
};