<template>
<transition name="modal-fade">
  <div id="modalBkg" class="modal_bkg">
    <div id="modal" style="background-image:none;border:1px solid gray;">
      <div id="modal-header">
        <slot name="modal_header">
          <div class="modal_title">{{title}}</div>
          <div class="modal_close close_button" @click="$emit('close')">×</div>
        </slot>
      </div>
      <div  class="modal_content" id="modal-content">
        <slot name="modal_content"></slot>
      </div>
      <div id="modal-footer">
        <slot name="modal_footer"></slot>
      </div>
    </div>
    
  </div>
  </transition>
</template>

<script>
/* eslint-disable */

export default {
  name: 'modal',
  data () {
    return {
    }
  },
  props: ['title', 'onBackClose'],
  components: {  },
  mounted: function (){
    if (this.onBackClose)
      document.addEventListener('click', this.backClick);
  },
  methods:{
    backClick(event){
      event.preventDefault();
      event.stopPropagation();
      if(event.target.id === "modalBkg" ){
        this.$emit("close");
        document.removeEventListener('click', this.backClick)
      }
    }
  }
}
</script>

<style scoped>
.modal_bkg {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.6);
}

#modal {
  border-radius: 5px;
  background: #fff;
  box-shadow: rgba(0,0,0,0.4) 0 12px 20px 8px;
  min-width: 360px;
  position: relative;
}

.modal_close {
  font-family: Helvetica,Arial,sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 12px;
  position: absolute;
  top: 20px;
  right: 5px;
  cursor: pointer;
  border: 0;
  outline: none;
}
.modal_title {
  font-size: 16px;
  /* font-weight: 200; */
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #d4d4d4;
}
.modal_content {
  padding: 20px 20px;
  text-align: center;
}
.clickable {
  cursor: pointer;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
