//TODO : Adapt markup infos within Markup bdd call (array / no array)
<template>
  <div class="panel_side">
    <div ref="panel_header" class="panel_header">
    </div>
    <div class="panel_content" :style="height = heightContent +'px'">
      <!-- <app-markup :class="{hideItem: !display.marker}" :selectedMenu=selectedMenu></app-markup> -->
      <app-list :class="{hideItem: !display.list}" :selectedMenu=selectedMenu></app-list>
    </div>
  </div>
</template>

<script>
  import markersListPanel from './markersListPanel.vue';
  require('../../../scripts/viewer/viewerMarkers.js');

  export default {
    name: 'App',
    data: function(){
        return{
          display : this.$root.$data.display,
          heightContent:0,
          selectedMenu: 'sheet',
          noViewer: this.$root.$data.noViewer
        }
    },
    components:{
      appList: markersListPanel
    },
    filters: {
    },
    computed: {
    },
    mounted: function () {
      this.$nextTick(function () {
      })
    },
    watch: {
    },
    methods:{
      panelMenu(type){
        this.display[type]= !this.display[type];
        if (type === "viewer3d" || type === "viewer2d"){
          console.log('resize !')
          setTimeout(() => {
            window.viewers.forEach(function(_viewer){
              _viewer.resize('', '', true);
            });
          }, 200);
        }
      },
      isActived(elt, type){
        if (elt === 'bt'){
          return this.selectedBt === type;
        }
        if (elt === 'menu'){
          return this.selectedMenu === type;
        } 
      },
      getHeight(event){
        var flexContainerH = document.querySelector('.flex-container').offsetHeight;
        var sidePanelHeaderH = this.$refs.panel_header.offsetHeight;
        this.heightContent = flexContainerH - sidePanelHeaderH;
      },
    }
  }
</script>

<style>
.panel_side {
  overflow: auto;
  background-color: rgb(239, 239, 239);
}
.panel_header_menus {
  font-size: 13px;
  height: 35px;
  line-height: 34px;
  display: flex;
  align-items: center;
  align-content: flex-start;
  /* border-top: 1px solid #aaaaaa; */
  border-bottom: 1px solid #aaaaaa;
  background-color: #ffffff;
}
.panel_header_menus > * {
  width: 35px;
  /* flex-grow: 1; */
  text-align: center;
  cursor: pointer;
}
.item {
  background-color: #ffffff;
}
.item.selected {
  background-color: #e4e4e4;
}
.noViewer {
  position: relative;
  overflow: hidden;
}
.noViewer:before {
  border-top: 1px solid #c3c3c3;
  content: '';
  position: absolute;
  top: 0; right: 0; left: -50%;
  transform: rotate(-45deg);
  transform-origin: 100% 0;
}
.hideItem {
  display: none;
}
#markupCreate {
  height: 100%;
  overflow: auto;
  position: relative;
  padding-top: 5px;
}
.panel_header, .panel_content {
  font-size: 12px;
}
.panel_header i {
  padding-right: 5px;
}
.header_logo {
  display: block;
  margin: auto;
  height: 80px;
}
.markers2D.svg_container{
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
}
.markers2D .circle{
  cursor: pointer;
}
.marker_ic:hover {
  color: blue;
  cursor: pointer;
}
.marker_ic.selected {
  color: blue;
}
.marker_infos {
  padding: 15px;
}
</style>