/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable import/first */
/**
 * @fileoverview DB-LAB Forge object class
 *
 * @author DB-Lab
 * Copyright 2019 DB-Lab
 */
/**
 * @namespace
 */


let helper  = require("../../tools/forgeApiHelper").helper;

if (window["Forge"] === undefined) window.Forge = {};

Forge.getToken = () => {
	return new Promise((resolve, reject) => {
		let xhr = new XMLHttpRequest();
		xhr.withCredentials = false;
		xhr.open("GET", FORGE_PUBLIC_URL +"/authenticate", false);
		xhr.setRequestHeader("Content-type", "application/json");
		xhr.onreadystatechange = function() {
			if (xhr.readyState === 4) {
				let resp = JSON.parse(xhr.response);
				resolve (resp)
			}
		};
		xhr.send(null);
	});
};

//DEPRECATED
Forge.getOnlineUrn = async (path64) => {
	return new Promise((resolve, reject) => {
		let data = JSON.stringify({
			"path64": path64,
		});
		let xhr = new XMLHttpRequest();
		xhr.withCredentials = false;
		xhr.open("POST", FORGE_PUBLIC_URL +"/forgeUrn");
		xhr.setRequestHeader("Content-type", "application/json");
		xhr.onreadystatechange = function() {
			if (xhr.readyState === 4) {
				let urn = xhr.response;
				// console.log(svfUrn);
				// getViewableItems
				// resolve (urn);
				Forge.getCollection(urn, true).then(function(collection){
					resolve(collection)
				});
			}
		};
		xhr.send(data);
	});
};

Forge.getPaths = async (modelId) => {
	return new Promise((resolve, reject) => {
		let data = JSON.stringify({
			"modelId": modelId,
		});
		let xhr = new XMLHttpRequest();
		xhr.withCredentials = false;
		xhr.open("POST", FORGE_PUBLIC_URL +"/forgePaths");
		xhr.setRequestHeader("Content-type", "application/json");
		xhr.onreadystatechange = function() {
			if (xhr.readyState === 4) {
				if(xhr.response === null || xhr.response === ""){
					reject();
				}
				else {
					let paths = JSON.parse(xhr.response);
					resolve(paths);
				}
			}
		};
		xhr.send(data);
	});
};

Forge.loadModelSingle = async (wrapper, svfPath, fileName, isOnline, callback) => {
	return new Promise(async (resolve, reject) => {
		wrapper.innerHTML = "";
		let options = {};
		
		if (isOnline){
			console.log("Load Model in Online Mode")
			let token = await Forge.getToken();
			console.log(token)
			options.env = "AutodeskProduction";
			options.api = "derivativeV2_EU";
			options.accessToken = token.access_token;
			options.useADP = false;
		}
		else {
			console.log("Load Model in Local Mode");
			options.env = "Local";
			options.useADP = false;
		}

		let config3d = {
			theme: "light-theme"
		};
	
		// Global variable viewerForge
		window.viewer = new Autodesk.Viewing.GuiViewer3D(wrapper, config3d);
		viewers.push(viewer);

		Autodesk.Viewing.Initializer(options, function(){
			if (!isOnline) {
				let hash = location.hash.replace('#/','');
				let hashElements = hash.split(",");
				const hashToken = hashElements[2];
				helper.getToken(hashToken).then(token=>{
					Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS = {
						dblab_authorization: token
					};
				});
			}
			viewer.initialize();

			//Add Logo
			let logoBox = document.createElement('div');
			logoBox.className = "logo_viewer";
			let logo = document.createElement('img');
			logo.src = "images/Batinbox_logo.png";
			logoBox.appendChild(logo);
			viewer.container.appendChild(logoBox)

			//Add Title
			let titleBox = document.createElement('div');
			titleBox.className = "title_viewer";
			let title = document.createElement('span');
			title.innerHTML = decodeURI(fileName);
			titleBox.appendChild(title);
			viewer.container.appendChild(titleBox)
			
			if(isOnline){
				let onDocumentLoadSuccess = function (doc) {
					console.log("Load Document Succeeded!");
					doc.downloadAecModelData(function(res){
						if(res){
							console.log("AecModelData detected!");
						}
					});
					
					svfPath = getModelfromViewable(
						doc,
						onModelLoadSuccess,
						onModelLoadError);  
				};

				//when the document failed to be loaded 
				let onDocumentLoadError = function (viewerErrorCode) {
					console.error("Document Load Failure, errorCode:" + viewerErrorCode);
				};

				let svfUrn = "urn:" + svfPath;
				Autodesk.Viewing.Document.load(
					svfUrn,
					onDocumentLoadSuccess,
					onDocumentLoadError );

				//when document is being loaded 
				let getModelfromViewable = (doc, onModelLoadSuccess, onModelLoadError)=>
				{
					//get available viewables
					let pathCollection = {
						path2d: [],
						path3d: []
					}
					let items2d = doc.getRoot().search({"type":"geometry", "role": "2d"});
					for (let i =0; i<items2d.length; ++i) {
						pathCollection.path2d.push({
							guid : items2d[i].data.guid,
							name : items2d[i].data.name,
							path: doc.getViewablePath(items2d[i])
						});
					}
					let items3d = doc.getRoot().search({"type":"geometry", "role": "3d"});
					for (let i =0; i<items3d.length; ++i) {
						pathCollection.path3d.push({
							guid : items3d[i].data.guid,
							name : items3d[i].data.name,
							path: doc.getViewablePath(items3d[i]),
						});
					}
				
					// Choose the first available viewables
					let initialViewable = items3d[0] || items2d[0];
					let svfUrl = doc.getViewablePath(initialViewable);

					return svfUrl;
				};
			}

			let loadOptions = {};

			viewer.loadModel(svfPath, 
				loadOptions,
				onModelLoadSuccess,
				onModelLoadError);

			//when the model is loaded 
			function onModelLoadSuccess(model) {
				console.log("Load Model Succeeded!");
				viewer.activeModel = model;

				viewer.navigation.toPerspective(true);
				viewer.setReverseZoomDirection(true);
				viewer.setZoomTowardsPivot(true);

				//delegate geometry loaded event
				viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
					onGeometryLoaded); 
				
			}
			function onGeometryLoaded(evt){
				viewer.removeEventListener(
					Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
					onGeometryLoaded);
				console.log( "Geometry Loaded!");
				Markers.initSvg([viewer]);
			}

			function onModelLoadError(viewerErrorCode) {
				console.error("Model Load Failure, errorCode:" + viewerErrorCode);
				reject(" Loading Failed!");
			}
		});
	});
};

Forge.sheetViewer = async (wrapper, urnPath, isOnline, callback) => {
	
	wrapper.innerHTML = "";
	let options = {};
	// console.log("Autodesk:"+Autodesk)
	options.env = "Local",
	options.useADP = false;

	let viewerConfig = {
		lightPreset: 8,
		viewerType: "GuiViewer3D", //['Viewer3D', 'GuiViewer3D']
		qualityLevel: [true, true],
		navigationTool: "freeorbit",
		progressiveRendering: true,
		//		backgroundColor:[3,4,5, 250, 250, 250]
		backgroundColor:[255,255,255, 115, 115, 115]
	//    backgroundColor: [0, 0, 0, 0, 0, 0]
	};
	let theme = {theme: "light-theme"};
	window.viewerN = new Autodesk.Viewing.GuiViewer3D(wrapper, theme);
	viewers.push(viewerN);
	
	Autodesk.Viewing.Initializer(options, function(){
		let hash = location.hash.replace('#/','');
		let hashElements = hash.split(",");
		const hashToken = hashElements[2];
		helper.getToken(hashToken).then(token=>{
			Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS = {
				dblab_authorization: token
			};
		});
		viewerN.initialize();
		
		let loadOptions = {};
		viewerN.loadModel(urnPath, 
			loadOptions,
			onModelLoadSuccess,
			onModelLoadError);

		//when the model is loaded 
		function onModelLoadSuccess(model) {
			console.log("Load Model Succeeded!");

			// viewerN.navigation.toPerspective(true);
			viewerN.setReverseZoomDirection(true);
			viewerN.setBackgroundColor(255, 255, 255, 255, 255, 255);
			// viewerN.setZoomTowardsPivot(true);

			//delegate geometry loaded event
			viewerN.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
				onGeometryLoaded); 
		}
		function onGeometryLoaded(evt){
			viewerN.removeEventListener(
				Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
				onGeometryLoaded);
			console.log( "Geometry Loaded!");
		}

		function onModelLoadError(viewerErrorCode) {
			console.error("Model Load Failure, errorCode:" + viewerErrorCode);
			reject(" Loading Failed!");
		}
	});
}

Forge.sheetViewerLoad = (path) => {
	Forge.unloadModel(viewerN.model, viewerN);
	function _onGeometryLoaded(evt) {
		viewerN.removeEventListener(
		Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
		_onGeometryLoaded);
		console.log('Geometry Loaded!');
		viewerN.activeModel = evt.model;
	}

	function _onLoadModelSuccess(model) {
		console.log('Load Model Succeeded!');
		viewerN.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
		_onGeometryLoaded);
	};
	var loadOptions = {};
	viewerN.loadModel(path, loadOptions, _onLoadModelSuccess);
}

Forge.getCollection = async (urnPath, isOnline) => {
	return new Promise(async (resolve, reject) => {
		let options = {};
		if (isOnline){
			let token = await Forge.getToken();
			console.log(token)
			options.env = "AutodeskProduction";
			options.api = "derivativeV2_EU";
			options.accessToken = token.access_token;
			options.useADP = false;
		}
		else {
			options.env = "Local",
			options.useADP = false;
		}
		
		Autodesk.Viewing.Initializer(options, function(){

				var urlPath = "";
				let onDocumentLoadSuccess = function (doc) {
					console.log("Load Document Succeeded!");
					doc.downloadAecModelData(function(res){
						if(res){
							console.log("AecModelData detected!");
						}
					});
					
					getModelfromViewable(doc);  
				};

				//when the document failed to be loaded 
				let onDocumentLoadError = function (viewerErrorCode) {
					console.error("Document Load Failure, errorCode:" + viewerErrorCode);
				};

				//when document is being loaded 
				let getModelfromViewable = (doc)=>
				{
					//get available viewables
					let pathCollection = {
						path2d: []
						// path3d: []
					}
					let items2d = doc.getRoot().search({"type":"geometry", "role": "2d"});
					for (let i =0; i<items2d.length; ++i) {
						pathCollection.path2d.push({
							guid : items2d[i].data.guid,
							name : items2d[i].data.name,
							path: doc.getViewablePath(items2d[i])
						});
					}

					resolve(pathCollection);
				};
			

				let urn = "urn:" + urnPath;
				Autodesk.Viewing.Document.load(
					urn,
					onDocumentLoadSuccess,
					onDocumentLoadError );
			// }
		});
	});
}

Forge.unloadModel = function(model, viewer) {
    return new Promise((resolve, reject)=>{
        viewer.impl.unloadModel(model);
            viewer.impl.sceneUpdated(true);
        return resolve();
    });
}

/**
 * Show a search bar in Forge viewer
 * @param {viewer} Forge viewer variable
 */
Forge.searchBar = function (viewer, callback) {
	let searchBox = document.createElement("div");
	let searchBarDom = document.createElement("input");
	searchBarDom.className = "in-viewer-search-bar";
	searchBarDom.id = "in-viewer-search-bar";
	searchBarDom.type = "search";

	searchBox.style.position = "absolute";
	searchBox.style.top = "0px";
	searchBox.style.left = "0px";
	searchBox.style.zIndex = "10";

	let searchList = document.createElement("div");

	searchBox.appendChild(searchBarDom);
	searchBox.appendChild(searchList);
	viewer.container.appendChild(searchBox);

	searchBarDom.addEventListener("change", function(e) {
		let str = e.target.value.trim();
		Forge.showObjects(viewer, str, function(viewer, dbIds){
		});
	});
};

/**
 * Show objects in the 3D scene
 * @param {viewer} Forge viewer variable (global)
 * @param {value} attributeName of objects in the 3D model
 * @return {String} the dbIds of 3D objects
 */
Forge.showObjects = function (viewer, value, callback) {
	viewer.search("\"" + value + "\"", function(dbIds) {
		console.log(dbIds.length);
		viewer.isolate(dbIds);
		viewer.fitToView(dbIds);
		viewer.select(dbIds, Autodesk.Viewing.SelectionMode.MIXED);
		if (callback){
			callback(viewer, dbIds);
		}
		return dbIds;
	}, function(error) {
	});
};

/**
 * Get object Revit ID on object selection in the 3D scene 
 * @param {viewer} Forge viewer variable
 * @return {String} the Revit ID
 */
Forge.getRevitId = function (viewer) {
	viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, function(event){
		if (event.dbIdArray.length === 1) {
			viewer.getProperties(event.dbIdArray[0], function(data) {
				if (data.name.indexOf("[") > -1 && data.name.indexOf("]") > -1){
					let regex = /\[(.*?)\]/;
					let revitId = regex.exec(data.name)[1];
					console.log(revitId);
					return revitId;
				}
			});
		}
	});
};

Forge.showModel = function(viewer, model) {
	return new Promise((resolve, reject)=> {
		let vm = new Autodesk.Viewing.Private.VisibilityManager(viewer.impl, model);
		let id = model.getRootId();
		vm.show(id);
		vm.setNodeOff(id, false);
		return resolve();
	});
}

Forge.hideModel = function(viewer, model) {
	return new Promise((resolve, reject)=> {
		let vm = new Autodesk.Viewing.Private.VisibilityManager(
			viewer.impl, model);
		let id = model.getRootId();
		vm.hide(id);
		vm.setNodeOff(id, true);
		return resolve();
	});
}

Forge.shadowModel = function(viewer, model, nodeOff=false) {
	viewerForge.impl.visibilityManager.hide(model.id, model);
	viewerForge.impl.visibilityManager.setNodeOff(model.id, nodeOff);
}

Forge.setPropertyPanelNode = function(viewer, nodeId) {
	viewer.getProperties(nodeId, (result)=>{
		if (result.name.startsWith("Body")) {
			let instanceTree = viewer.model.getData().instanceTree;
			let parentId = instanceTree.getNodeParentId(nodeId);
			viewer.select([parentId]);
		}
		else if(result.properties) {
			let propertyPanel = viewer.getPropertyPanel(true);
			propertyPanel.setNodeProperties(nodeId);
			propertyPanel.setProperties(result.properties);
		}
	});
};

Forge.setActiveModel = function(viewer, model){
	return new Promise((resolve, reject)=> {
		if (viewer.model){
			viewer.model.selector.setSelection([]);
		}
		viewer.model = model;
		let modelId = model.getRootId();

		//    Forge.setPropertyPanelNode(viewer,modelId);
		return resolve();
	});
};

Forge.setCurrentModel = function(viewer, model) {
	return new Promise((resolve, reject)=> {
		if (viewer.model){
			viewer.model.selector.setSelection([]);
		}
		viewer.model = model;
		let modelId = model.getRootId();

		let dbId = [];
		dbId.push(parseInt(modelId));
		model.selector.setSelection(dbId);
		viewer.fitToView(dbId);

		Forge.setPropertyPanelNode(viewer,modelId);
		return resolve();
	});
};

Forge.unsetCurrentModel = function(viewer) {
	return new Promise((resolve, reject)=> {
		if (viewer.model){
			viewer.model.selector.setSelection([]);
			return resolve();
		}
	});
};